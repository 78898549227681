<template>
  <v-container>
    <v-row>
      <v-col v-show="$vuetify.breakpoint.lgAndUp" cols="12" lg="3">
        <TheSideNavigation />
      </v-col>
      <v-col cols="12" lg="9">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheSideNavigation from "@/components/TheSideNavigation";

export default {
  name: "Panel",
  components: {
    TheSideNavigation
  }
};
</script>
